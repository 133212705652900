import React from 'react';
import {renderRichText} from "gatsby-source-contentful/rich-text";
import vaultsImage1 from "../../images/Oracle.png";
import vaultsImage2 from "../../images/Auction.png";
import vaultsImage3 from "../../images/ReservePool.svg";
import vaultsImage4 from "../../images/LiquidationEngine.svg";

import {BLOCKS} from "@contentful/rich-text-types";

const images = [
    vaultsImage1,vaultsImage2,vaultsImage3,vaultsImage4
]
const FourLogosSection = ({contentful, fullWidth}) => {
    const options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <div className={'text'}>{children}</div>,
        },
    };


    return (
        <div>
            <div className={'container container-slim'}>
                <h3>{contentful.title}</h3>
                <h6>{contentful.subtitle && renderRichText(contentful.subtitle)}</h6>

                <div className={'grid gap-20 lg:grid-cols-2 mt-20'}>
                    {contentful.elements.map((element, index) => {
                        const image = images[index];
                        return (
                            <div className={`flex items-start ${index%2 === 0 ? 'md:pr-11' : 'md:pl-11'}`}>
                                <div className={'flex flex-wrap justify-between'}>
                                    <div className={`${fullWidth ? 'basis-full' : 'basis-full md:basis-4/12 pb-10 md:pb-0'}`}>
                                        <div className={`${fullWidth ? 'image pr-10 h-[150px]' : 'image pr-10 h-[150px]'}`}>
                                            <img src={image} alt={element.title} className={'max-h-full'}/>
                                        </div>
                                    </div>
                                    <div className={`${fullWidth ? 'basis-full pt-4' : 'basis-full md:basis-8/12'}`}>
                                        <h4>{element.title}</h4>
                                        {element.body && renderRichText(element.body, options)}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>


            </div>
        </div>
    );
};

export default FourLogosSection;
